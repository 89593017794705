@font-face {
  font-family: main;
  src: url(/Montserrat-Regular.ttf);
}

p {
  font-family: main, sans-serif;
}

h2 {
  font-family: main, sans-serif;
}

h4 {
  font-family: main, sans-serif;
}

h3 {
  font-family: main, sans-serif;
}

h5 {
  font-family: main, sans-serif;
}

button {
  font-family: main, sans-serif;
}

a {
  font-family: main, sans-serif;
}

.fa-gap {
  margin-right: 20px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.title-logo {
  height: 70px;
}

.navbar {
  background-color: #00000000 !important;
}

.title-content {
  position: relative;
  margin: 5px;
  padding: 5px;
  z-index: 100;
  text-align: center;
  font-size: 2.5em;
  z-index: 10;
}

.title-content2 {
  position: absolute;

}

.screen-shot {
  position: absolute;
  top: 100px;
  left: calc(50% - 13px);
  transform: translate(-50%, 0);
  min-width: 318px;
  min-height: 300px;
  background: #00000000;
}

.screen-shot-content {
  position: absolute;
  top: 530px;
  left: calc(50% - 35px);
  transform: translate(-50%, 0);
  min-width: 250px;
  width: 250px;
  font-size: 0.6em;
  margin: 20px;
  color: white;
  background: #000000AA;
  border-radius: 5px;
  padding: 10px;
  font-family: main, sans-serif;
}

.phone-image {
  z-index: 10;
  position: relative;
  width: 100%;
  height: 100vh;
  background-size: 1500px 1290px;
  background-repeat: no-repeat;
  background-image: url(/hand_phone.png);
  background-position: center top;
  /* background-attachment: fixed; */
  padding: 0px;
}

.ss-shown{
  opacity:1 !important;  

  transition: opacity 0.4s linear;
  -webkit-transition: opacity 0.4s linear;
  -moz-transition: opacity 0.4s linear;
  -ms-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
}

.animation-image {
  opacity:0;

  transition: opacity 0.4s linear;
  -webkit-transition: opacity 0.4s linear;
  -moz-transition: opacity 0.4s linear;
  -ms-transition: opacity 0.4s linear;
  -o-transition: opacity 0.4s linear;
}

.sign-up {
  background-color: #00AADD !important;
  color: #FFFFFF !important;
  border: 0px solid #00DDFF !important;
}

.navbar-menu {
  background: #000000 !important; 
}

.navbar-item {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: border-color 300ms 0ms; /*, background-color 300ms 0ms; */

  /* font-family: brandon-grotesque-black, sans-serif; */
  font-size: 0.8em;
  color: #FFFFFF !important;
  
  border: 5px solid #00000000;
}

.navbar-item-end {
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.navbar-item:hover {
  background-color: #00000000 !important;
  color: #FFFFFF !important;

  border-top: 5px solid #00DDFF;
  border-bottom: 5px solid #00DDFF;
}

.navbar-link:hover {
  /* background-color: #FFFFFF !important; */
  color: #FFFFFF !important;
}

.navbar-live {
  background-color: #00000000;
  border-top: 5px solid #00DDFF;
  border-bottom: 5px solid #00DDFF;
}

.section-title {
  font-size: 2.5em;
}

.partner-cell {
  border-radius: 15px;
  border: 3px solid #00DDFF;
  display: inline-block;
  width: calc(100% - 40px);
  min-height: 300px;
  padding: 30px;
  margin: 20px;
}

.partner-cell-logo {
  float: right;
  padding: 20px;
}

.partner-cell-image {
  height: 200px;
}

body {
  background-image: linear-gradient(to bottom right, #000, #484c54);
}

.main {
  min-height: 150vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #00000000;
}

.small_features_div {
  float: right;
  width: 250px;
}

.content {
  position: relative;
  margin: 50px;
  background-color: #000000AA;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
}

.content-main {
  position: relative;
  background-color: #000000FF;
  border-radius: 10px;
  padding: 40px;
  z-index: 100;
}


.content-partners {
  position: relative;
  margin: 50px;
  background-color: #000000DD;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  
}

.partners-container {
  
}

.features-ul li {
  padding-bottom: 30px;
}

.contact-ul li {
  padding-bottom: 30px;
}

.major-features-ol li {
  padding-bottom: 30px;
}

.background {
  height: 100%;
  z-index: 1;
}  

.desktop-screenshot {
  width: 100%;
  text-align: center;
  padding: 20px;
}

.screenshot-title {
  padding: 10px;
  border-radius: 5px;
  border: 4px solid #00DDFF;
  color: #00DDFF;
}

.screenshots-panel {
  overflow-x: scroll;
}

.screenshots-panel::-webkit-scrollbar {
  display: none;
}

.screenshots-panel {
  -ms-overflow-style: none;
}

.screenshots-table {
  background-color: #00000000 !important;
  padding: 40px;
  margin-top: 50px;
  table-layout: fixed;
}

.screenshots-td {
  width: 350px;
}

.screenshots-expand-div {
  color: #00DDFF;
  float: right;
}

.screenshots-gap-div {
  height: 50px;
}

.partners-map {
  position: absolute;
  width: 100%;
  background-color: #00000000;
}

.screenshots {
}

.screenshots-table tr:hover {
  background-color: #00000000 !important;
}

.screenshots-table td {
  padding: 20px;
  text-align: center;
  
}

.content-paragraph {
  font-size: 0.8em;
  /* font-family: brandon-grotesque-black, sans-serif; */
}

.floating-phone {
  position: absolute;
}

.floating-target {
  position: absolute;
}

.floating-money {
  position: absolute;
}

.navbar {
  z-index: 1000;
}

.strong {
  color: #00DDFF;
  text-shadow: 0 0 10px #0077AA, 0 0 20px #003366;
  //font-size: 1.2em;
}

.contact-content {
  position: absolute;
  width: 50%;
  right: 50px;
  margin-top: 50px;
  
  background-color: #000000DD;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
}

.contact-paragraph {
  font-size: 0.8em;
  /* font-family: brandon-grotesque-black, sans-serif; */
}

.stacked-map-1 {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 5;
}

.stacked-map-2 {
  position: absolute;
  left: 0px;
  right: 0px;
  z-index: 10;
}


.feature-line {
  color: #00DDFF;
}

.place-line {
  color: #00DDFF;
}

.map-image {
  border-bottom: 1px solid #FFFFFF;
}

.legal {
  padding: 20px;
  font-size: 0.6em;
  background-color: #000000;
}

.legal-image {
  float: right;
  height: 130px;
}

.partners-space {
  min-height: 130vh;
}

.contact-space {
  min-height: 150vh;
}

.animated-slow-appear {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: margin-left 300ms 0ms, opacity 300ms 0ms;
  
  overflow: hidden;
  margin-left: 100vw;
  opacity: 0;
}

.animated-slow-appear.show {
  opacity: 1;
  margin-left: 0vw;
}

.intro {
  z-index: 0;
  position: relative;
}

.features-ul {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  transition: margin-left 300ms 0ms, opacity 300ms 0ms;
  
  overflow: hidden;
  height: 0px;
  opacity: 0;
}

.features-ul.show {
  height: auto;
  opacity: 1;
}

.features-expand-div {
  color: #00DDFF;
  margin-left: auto;
}

.major-features-li {
  border-bottom: 1px solid #FFFFFF;
  margin-bottom: 20px;
}

.modal-background {
  position: fixed;
  top: 0px;
  left:  0px;
  z-index:  9999;
  background:  #00000099;
  width:  100vw;
  height: 100vh;
}

.modal-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #77777766;
  padding: 50px;
  border-radius: 15px;
}

label {
  display: block;
}

input {
  display: block;
  padding: 10px;
  font-size: 1.2em;
}

.loginButtons {
  padding: 10px;
  margin-top: 10px;
  margin-right: 10px;
  
  font-size: 1.0em;
  
  border-radius: 5px;
}

.logo-image {
  width: 80px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 40px;
}

